import {
  AutocompleteCloseReason,
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import { ChangeEvent, KeyboardEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetDropdownWithSearchAPI } from 'src/apis/dropdownAPI/get';
import { FilterTag } from 'src/components/layout/FilterLayout/components/MainContainer/components/FilterTag';
import { Autocomplete } from 'src/components/mui-components';
import { useMenu } from 'src/hooks/useMenu';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { stringToPascal } from 'src/utils/string';
import { translationAnyText } from 'src/utils/translation';
import { useDebounce } from 'use-debounce';
import { ISearchFilter } from '../../../types';
import { getFilterIdFromContentUrl } from '../../../utils';
import styles from './FilterItem.module.scss';
import { IFilter } from './FilterItem.types';

interface IFilterItem extends ISearchFilter {
  'data-automation-id': string;
  onChange: (v: IFilter) => void;
  hideRadios?: boolean;
}

export const FilterItem = ({
  contentUrl,
  filterName,
  hideRadios,
  id,
  onChange,
  ...rest
}: IFilterItem) => {
  const { t } = useTranslation('assignFlow');

  const ref = useRef<HTMLDivElement>(null);
  const { anchorEl, menuIsOpen, menuOnClose, setAnchorEl } = useMenu();

  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText] = useDebounce(searchText, 500);

  const filterId = getFilterIdFromContentUrl(contentUrl);
  const pageIdentifier = useGetCurrentPageIdentifier();
  const { dropdownList, isFetching } = useGetDropdownWithSearchAPI(
    {
      key: `filterInput${filterId}`,
      MSWKey: `FILTER_INPUT_${filterId}`,
      path: `/${contentUrl}?pageIdentifier=${pageIdentifier}`,
      searchText: debouncedSearchText,
    },
    menuIsOpen,
  );

  const [isInclude, setIsInclude] = useState<'is' | 'is-not'>('is');
  const [filters, setFilters] = useState<IFilter['values']>([]);

  const popperOnClose = () => {
    menuOnClose(() => {
      onChange({
        isInclude: isInclude === 'is',
        values: filters,
      });
    });
  };

  const filterTagOnRemove = () => {
    menuOnClose();
    onChange({
      isInclude: true,
      values: [],
    });
    setIsInclude('is');
    setFilters([]);
  };

  const radioOnChange = (_: ChangeEvent<HTMLInputElement>, v: string) => {
    if (v !== 'is' && v !== 'is-not') {
      return;
    }
    setIsInclude(v);
  };

  return (
    <FilterTag
      data-automation-id={rest['data-automation-id']}
      items={filters?.length ? filters : [{ label: 'Any' }]}
      onClick={(e) => setAnchorEl(e.currentTarget)}
      panelIsOpen={menuIsOpen}
      ref={ref}
      removeFilterTag={filters?.length ? filterTagOnRemove : undefined}
      title={translationAnyText(t, `FilterItems.${stringToPascal(filterName)}`)}
    >
      {menuIsOpen ? (
        <Popper
          anchorEl={anchorEl}
          className={styles.popper}
          data-automation-id={`${rest['data-automation-id']}-Popper`}
          open={menuIsOpen}
          placement="bottom-start"
        >
          <ClickAwayListener onClickAway={popperOnClose}>
            <Paper className={styles.paper} elevation={4} sx={{ width: 500 }}>
              <Stack gap={2}>
                {!hideRadios ? (
                  <FormControl>
                    <RadioGroup
                      className={styles.radioGroup}
                      name="IsOrIsNotRadio"
                      onChange={radioOnChange}
                      row
                      value={isInclude}
                    >
                      <FormControlLabel
                        aria-label={t('FilterItems.IsRadio')}
                        data-automation-id={`${rest['data-automation-id']}-IsRadio`}
                        control={<Radio />}
                        label={t('FilterItems.IsRadio')}
                        value="is"
                      />
                      <FormControlLabel
                        aria-label={t('FilterItems.IsNotRadio')}
                        data-automation-id={`${rest['data-automation-id']}-IsNotRadio`}
                        control={<Radio />}
                        label={t('FilterItems.IsNotRadio')}
                        value="is-not"
                      />
                    </RadioGroup>
                  </FormControl>
                ) : null}
                <Autocomplete
                  data-automation-id={`${rest['data-automation-id']}-Autocomplete`}
                  disableCloseOnSelect
                  disablePortal
                  filterSelectedOptions
                  getOptionDisabled={() => isFetching}
                  inputOnChange={setSearchText}
                  inputProps={{ autoFocus: true }}
                  inputValue={searchText}
                  isOptionEqualToValue={(o, v) => o.value === v.value}
                  label={translationAnyText(t, `FilterItems.${stringToPascal(filterName)}`)}
                  loading={isFetching}
                  multiple
                  noOptionsText={t('FilterItems.NoLabels')}
                  onChange={(event, newValue, reason) => {
                    if (
                      event.type === 'keydown' &&
                      (event as KeyboardEvent).key === 'Backspace' &&
                      reason === 'removeOption'
                    ) {
                      return;
                    }
                    setFilters(newValue as IFilter['values']);
                  }}
                  onClose={(_, reason: AutocompleteCloseReason) => {
                    if (reason === 'escape') {
                      popperOnClose();
                    }
                  }}
                  open
                  options={dropdownList}
                  placeholder={translationAnyText(t, `FilterItems.${stringToPascal(filterName)}`)}
                  PopperComponent={Popper}
                  size="small"
                  value={filters}
                />
              </Stack>
            </Paper>
          </ClickAwayListener>
        </Popper>
      ) : null}
    </FilterTag>
  );
};
