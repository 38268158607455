import { Stack } from '@mui/material';
import heatmap from 'src/assets/styles/variables/colors/states/heatmap.module.scss';
import { Typography } from 'src/components/mui-components';
import { getAvailabilityColor } from 'src/screens/ResourcePlanner/helper/getAvailabilityColor';
import { roundNumberToTwoDigits as round } from 'src/utils/number';
import styles from './HoursBar.module.scss';

interface IHoursBar {
  availableHours: number;
  totalHours: number;
  addedHours?: number;
}

export const HoursBar = ({ addedHours = 0, availableHours, totalHours }: IHoursBar) => {
  const noCapacity = !availableHours && !totalHours;

  if (noCapacity) {
    return <Stack className={styles.progressBarBg} />;
  }

  const remainingHours = Math.round(availableHours - addedHours);
  const noMoreHours = remainingHours <= 0;
  const exceededTotalHours = remainingHours < 0;
  const utilizedHours = round(totalHours - availableHours + addedHours);
  const utilizedProgress = totalHours === 0 ? 0 : round(utilizedHours / totalHours); // 0-1 in 2 decimal places

  return (
    <Stack aria-live="polite" className={styles.progressBarBg}>
      <Stack
        className={styles.bar}
        sx={{
          backgroundColor: getAvailabilityColor(utilizedProgress),
          flexBasis: exceededTotalHours ? 0 : `${(1 - utilizedProgress) * 100}%`,
          width: noMoreHours ? 0 : 'auto',
        }}
      >
        <Typography className={styles.hours}>{remainingHours}h</Typography>
      </Stack>
      {utilizedHours ? (
        <Stack
          className={styles.bar}
          sx={{
            backgroundColor: exceededTotalHours ? '#c92726' : heatmap.colorHeatmapSuccess,
            flexBasis: exceededTotalHours ? '100%' : `${utilizedProgress * 100}%`,
          }}
        >
          <Typography className={styles.hours} color="#fff">
            {Math.round(utilizedHours)}h
          </Typography>
        </Stack>
      ) : null}
    </Stack>
  );
};
