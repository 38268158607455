import { Clear, Search } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Rating,
} from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetDropdownWithSearchAPI } from 'src/apis/dropdownAPI/get';
import elements from 'src/assets/styles/variables/colors/global/elements.module.scss';
import pinks from 'src/assets/styles/variables/colors/themes/pink.module.scss';
import { CircularProgress, IconButton, TextField, Typography } from 'src/components/mui-components';
import { HighlightMatchingText } from 'src/components/utils/HighlightMatchingText';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import { useKeyboardControl } from 'src/screens/ResourcePlanner/components/AssignFlow/hooks';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { useDebounce } from 'use-debounce';
import { IFilterCompetence } from './CompetenceDialog.types';

interface ICompetenceDialog {
  competence: IFilterCompetence[];
  setCompetence: Dispatch<SetStateAction<IFilterCompetence[]>>;
  open: boolean;
}

export const CompetenceDialog = ({ competence, setCompetence, open }: ICompetenceDialog) => {
  const { t } = useTranslation('assignFlow');
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 500);

  const filterId = 'competence';
  const contentUrl = `api/v2/filters/${filterId}/content`;
  const pageIdentifier = useGetCurrentPageIdentifier();
  const {
    dropdownList: d,
    isError,
    isFetching,
  } = useGetDropdownWithSearchAPI(
    {
      key: `filterInput${filterId}`,
      MSWKey: `FILTER_INPUT_${filterId}`,
      path: `/${contentUrl}?pageIdentifier=${pageIdentifier}`,
      searchText: debouncedQuery,
    },
    open,
  );

  const dropdownList = d.map((item) => ({
    ...item,
    levels: [],
  }));

  const competenceOnClick = (item: IFilterCompetence) => {
    setCompetence((prev) => {
      if (prev.find((c) => c.value === item.value)) {
        return prev.filter((c) => c.value !== item.value);
      }
      return [...prev, item];
    });
  };

  const competenceLevelOnClick = (item: IFilterCompetence, level: number | null) => {
    setCompetence((prev) => {
      const competenceList = [...prev];
      const targetIndex = prev.findIndex((c) => c.value === item.value);
      const levels = level ? Array.from({ length: 5 - level + 1 }, (_, i) => i + level) : [];
      competenceList[targetIndex] = { ...competenceList[targetIndex], levels };
      return [...competenceList];
    });
  };

  const getCompetenceIsChecked = (item: IFilterCompetence) =>
    competence.find((c) => c.value === item.value) !== undefined;

  const getCompetenceLevel = (item: IFilterCompetence) => {
    const selected = competence.find((c) => c.value === item.value);
    return selected?.levels[0] ?? 0;
  };

  const { initialFocusElement, searchContainerResults, textFieldOnKeyDown } = useKeyboardControl(
    {},
  );

  useEffect(() => {
    setCompetence(competence);
  }, [competence, open, setCompetence]);

  return (
    <>
      <Box p={2} pb={0}>
        <TextField
          ariaLabel={t('FilterItems.SearchInAllCompetences')}
          data-automation-id="SearchCompetence"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {query ? (
                  <IconButton
                    data-automation-id="SearchForTasksInputClearButton"
                    onClick={() => setQuery('')}
                    size="small"
                    title={t('ClearSearchButtonText')}
                  >
                    <Clear fontSize="small" />
                  </IconButton>
                ) : null}
                <Search fontSize="small" />
              </InputAdornment>
            ),
            inputProps: { autoFocus: true },
          }}
          inputRef={initialFocusElement}
          label={t('FilterItems.SearchInAllCompetences')}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={textFieldOnKeyDown}
          size="small"
          value={query}
        />
      </Box>
      <ResponseHandler
        EmptyComponent={<Typography textAlign="center">{t('NoResults')}</Typography>}
        isLoading={isFetching}
        isError={isError}
        isEmpty={!dropdownList.length}
        LoadingComponent={<CircularProgress sx={{ my: 10 }} />}
      >
        <List dense ref={searchContainerResults} sx={{ maxHeight: '40vh', overflow: 'auto', p: 2 }}>
          {dropdownList.map((c) => (
            <ListItem
              data-automation-id={`CompetenceItem-${c.value}`}
              disablePadding
              key={c.value}
              sx={{
                backgroundColor: getCompetenceIsChecked(c) ? pinks.themeColorPinkLighter : '',
                position: 'relative',
              }}
            >
              <ListItemButton
                onClick={() => competenceOnClick(c)}
                sx={{
                  '&[aria-selected="true"]': { backgroundColor: elements.colorSelected },
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={getCompetenceIsChecked(c)}
                    disableRipple
                    edge="start"
                    inputProps={{ 'aria-labelledby': c.value }}
                    tabIndex={-1}
                  />
                </ListItemIcon>
                <ListItemText
                  id={c.value}
                  primary={<HighlightMatchingText name={c.label} matchName={debouncedQuery} />}
                  sx={{ mr: 17 }}
                />
              </ListItemButton>
              {getCompetenceIsChecked(c) ? (
                <Rating
                  data-automation-id={`CompetenceItemRating-${c.value}`}
                  onChange={(_, v) => competenceLevelOnClick(c, v)}
                  sx={{
                    insetBlockStart: '50%',
                    insetInlineEnd: 16,
                    position: 'absolute',
                    transform: 'translateY(-50%)',
                  }}
                  value={getCompetenceLevel(c)}
                />
              ) : null}
            </ListItem>
          ))}
        </List>
      </ResponseHandler>
    </>
  );
};
