import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiLineTranslation } from 'src/components/utils/MultiLineTranslation';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from 'src/components/mui-components';
import { Close, LightbulbOutlined } from '@mui/icons-material';

interface ITipsButton {
  widgetKey: 'BillablePercentage' | 'ExternalPercentage' | 'RegistrationAccuracy';
}

export const WidgetTipsButton = ({ widgetKey }: ITipsButton) => {
  const { t } = useTranslation('frontpage');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const close = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button
        variant="text"
        data-automation-id="WidgetTipsButton"
        startIcon={<LightbulbOutlined />}
        onClick={() => setIsOpen(true)}
      >
        {t('WidgetTipsButton')}
      </Button>
      <Dialog open={isOpen} onClose={close}>
        <DialogTitle data-automation-id={`WidgetTipsHeader${widgetKey}`}>
          <Stack direction="row" justifyContent="space-between">
            {t(`WidgetTipsHeader${widgetKey}`)}
            <IconButton
              data-automation-id="WidgetTipsModalClose"
              onClick={close}
              title={t('WidgetTipsModalClose')}
            >
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent
          data-automation-id={`WidgetTipsContent${widgetKey}`}
          sx={{ minWidth: '400px' }}
        >
          <MultiLineTranslation translatedContent={t(`WidgetTipsContent${widgetKey}`)} />
        </DialogContent>
      </Dialog>
    </>
  );
};
