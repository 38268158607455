import { ClickAwayListener, Paper, Popper } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterTag } from 'src/components/layout/FilterLayout/components/MainContainer/components/FilterTag';
import { FeatureControl } from 'src/components/utils/FeatureControl';
import { useMenu } from 'src/hooks/useMenu';
import { translationAnyText } from 'src/utils/translation';
import styles from './FilterItemCompetence.module.scss';
import { CompetenceDialog, IFilterCompetence } from './components/CompetenceDialog';

interface IFilterItemCompetence {
  onChange: (c: IFilterCompetence[]) => void;
}

export const FilterItemCompetence = ({ onChange }: IFilterItemCompetence) => {
  const { t } = useTranslation('assignFlow');

  const ref = useRef<HTMLDivElement>(null);
  const { anchorEl, menuIsOpen, menuOnClose, setAnchorEl } = useMenu();

  const [competence, setCompetence] = useState<IFilterCompetence[]>([]);

  const popperOnClose = () => {
    menuOnClose(() => {
      onChange(competence);
    });
  };

  const clearCompetence = () => {
    setCompetence([]);
    onChange([]);
  };

  return (
    <FeatureControl indexControlName="Competences">
      <FilterTag
        data-automation-id="FilterItem-Competence"
        items={
          competence.length ? competence : [{ label: translationAnyText(t, `FilterItems.Any`) }]
        }
        onClick={(e) => setAnchorEl(e.currentTarget)}
        panelIsOpen={menuIsOpen}
        ref={ref}
        removeFilterTag={competence.length ? clearCompetence : undefined}
        title={translationAnyText(t, 'FilterItems.Competence')}
      >
        {menuIsOpen ? (
          <Popper
            anchorEl={anchorEl}
            className={styles.popper}
            data-automation-id="FilterItem-Competence-Popper"
            open={menuIsOpen}
            placement="bottom-start"
          >
            <ClickAwayListener onClickAway={popperOnClose}>
              <Paper className={styles.paper} elevation={4}>
                <CompetenceDialog competence={competence} open setCompetence={setCompetence} />
              </Paper>
            </ClickAwayListener>
          </Popper>
        ) : null}
      </FilterTag>
    </FeatureControl>
  );
};
