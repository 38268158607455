import {
  InputAdornment,
  InputBaseComponentProps,
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material';
import { ForwardedRef, Ref, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DataAutomationId } from 'src/utils/ts-utils';
import { CircularProgress } from '../CircularProgress';
import { TextField } from '../TextField';

export interface AutocompleteProps<T>
  extends Omit<
      MuiAutocompleteProps<T, boolean | undefined, boolean | undefined, boolean | undefined>,
      'renderInput'
    >,
    DataAutomationId {
  inputOnChange?: (v: string) => void;
  inputProps?: InputBaseComponentProps;
  inputStyles?: React.CSSProperties;
  label?: string;
  renderInput?: MuiAutocompleteProps<
    T,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  >['renderInput'];
}

export const AutocompleteInner = <T,>(
  { inputOnChange, inputProps, inputStyles, label, placeholder, ...rest }: AutocompleteProps<T>,
  ref: Ref<HTMLDivElement>,
) => {
  const { t } = useTranslation('filter');

  return (
    <MuiAutocomplete
      ChipProps={{ color: 'primary', size: 'small', ...rest.ChipProps }}
      openText={t('FilterAutocompleteOptionsListOpen')}
      closeText={t('FilterAutocompleteOptionsListClose')}
      clearText={t('FilterAutocompleteClearSelectedOptions')}
      loadingText={t('FilterAutocompleteOptionsLoading')}
      noOptionsText={t('FilterAutocompleteNoOptionsAvailable')}
      ref={ref}
      renderInput={(params) => (
        <TextField
          ariaLabel={t('FilterAutocompleteSearchPlaceholder')}
          data-automation-id={`${rest['data-automation-id']}TextField`}
          label={label}
          onChange={(e) => inputOnChange?.(e.target.value)}
          placeholder={placeholder ?? t('FilterAutocompleteSearchPlaceholder')}
          {...params}
          inputProps={{
            ...params.inputProps,
            ...inputProps,
            sx: {
              ...inputStyles,
            },
          }}
          // Putting inputProps (lowercase) inside InputProps (uppercase)
          // Causes inputProps (lowercase) to be overridden in some cases
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {rest.loading && <CircularProgress size={20} />}
                {params.InputProps.endAdornment}
              </InputAdornment>
            ),
          }}
        />
      )}
      {...rest}
    />
  );
};

export const Autocomplete = forwardRef(AutocompleteInner) as <T>(
  props: AutocompleteProps<T> & { ref?: ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof AutocompleteInner>;
