import { ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ToastifyAlert,
} from 'src/components/mui-components';
import { useInitialFocus } from 'src/hooks/useInitialFocus';

import { DialogProps } from '@mui/material';
import { UseMutateFunction } from '@tanstack/react-query';

interface IDeleteDialogProps extends Omit<DialogProps, 'onClose'> {
  closeDialog: () => void;
  itemId: number;
  name: string;
  deleteFunction: UseMutateFunction<any, unknown, number | undefined, unknown>;
  headerOverride?: string;
  contentOverride?: string | ReactNode;
  toastSuccessOverride?: string;
  toastErrorOverride?: string;
}

export const DeleteDialog = ({
  closeDialog,
  itemId,
  name,
  deleteFunction,
  open,
  headerOverride,
  contentOverride,
  toastSuccessOverride,
  toastErrorOverride,
  ...rest
}: IDeleteDialogProps) => {
  const { t } = useTranslation('systemAdministration');

  // Initial focus
  const initialFocusElement = useRef<HTMLButtonElement | null>(null);
  useInitialFocus(initialFocusElement);

  // Actions
  const handleDelete = () => {
    deleteFunction(itemId, {
      onSuccess: () => {
        toast(
          <ToastifyAlert
            title={
              toastSuccessOverride ||
              t('modals.DialogDeleteItemToastSuccessTitle', { ITEM_NAME: name })
            }
            color="success"
            data-automation-id="DeleteItemDialogToastSuccess"
          />,
          {
            autoClose: 4000,
            closeButton: false,
          },
        );
      },
      onError: (error) => {
        toast(
          <ToastifyAlert
            title={
              toastErrorOverride || t('modals.DialogDeleteItemToastErrorTitle', { ITEM_NAME: name })
            }
            description={`${error}`}
            color="error"
            data-automation-id="DeleteItemDialogToastError"
          />,
          {
            closeButton: true,
          },
        );
      },
    });
    closeDialog();
  };

  const handleCancel = () => {
    closeDialog();
  };

  return (
    <Dialog {...rest} open={open} onClose={handleCancel} data-automation-id="DeleteItemDialog">
      <DialogTitle>
        {headerOverride || t('modals.DialogDeleteItemHeader', { ITEM_NAME: name })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ whiteSpace: 'pre-line' }}>
          {contentOverride || t('modals.DialogDeleteItemContentText')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleCancel}
          data-automation-id="DeleteItemDialogButtonCancel"
        >
          {t('modals.DialogDeleteItemButtonCancelText')}
        </Button>
        <Button
          variant="contained"
          onClick={handleDelete}
          data-automation-id="DeleteItemDialogButtonDelete"
          ref={initialFocusElement}
        >
          {t('modals.DialogDeleteItemButtonDeleteText')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
